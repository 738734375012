<template>
  <div>
    <v-simple-table
      fixed-header
      height="calc(100vh - 145px)"
      class="table-padding-2-no-top"
    >
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th class="text-center" style="width: 150px">
              <SelectEMarket
                class="c-input-xs input-filter"
                :label="$t('labels.e_market')"
                :placeholder="$t('labels.e_market')"
                name="id_e_market"
                sort-name="e_market_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center">
              <InputFilter
                :label="$t('labels.shop_name')"
                :placeholder="$t('labels.shop_name')"
                name="shop_name"
                sort-name="shop_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th class="text-center" style="width: 200px">
              <InputFilter
                :label="$t('labels.shop_id')"
                :placeholder="$t('labels.shop_id')"
                name="id_shop"
                sort-name="id_shop"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th
              class="text-center"
              style="width: 165px"
              v-if="checkPermission(['administration_invoice'])"
            >
              <SelectFilter
                :label="$t('labels.publish_invoice')"
                :placeholder="$t('labels.publish_invoice')"
                name="publish_invoice"
                sort-name="publish_invoice"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
                :options="publishInvoiceOptions"
              />
            </th>
            <th class="text-center">
              {{ $t("labels.token") }}
            </th>
            <th class="text-center">
              {{ $t("labels.active") }}
            </th>
            <th class="text-center">
              <div v-html="$t('labels.shop_auto_generate_sku')"></div>
            </th>
            <!--<th class="text-center" style="min-width: 118px">
              <div v-html="$t('labels.shop_auto_sync_stock')"></div>
            </th> -->
            <!-- <th class="text-center" style="min-width: 168px">
              <div v-html="$t('labels.shop_auto_sync_order')"></div>
            </th> -->
            <!--<th class="text-center" style="min-width: 135px">
            CẤU HÌNH <br>
            Đồng bộ & Chuẩn bị đơn hàng <br>
            (Giao tại bưu cục)
            </th>
            <th class="text-center" style="min-width: 110px">
              <div v-html="$t('labels.shop_auto_sync_config')"></div>
            </th>-->
            <th class="text-center" style="width: 165px">
              <v-btn small color="primary" @click="addNewShop">
                {{ $t("labels.add") }}
              </v-btn>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id" class="text-center">
            <!-- <td>
              <v-autocomplete class="c-input-small input-disabled-bold" v-model="item.id_warehouse"
                :items="warehouseOptions" :disabled="!item.editing" :label="$t('labels.warehouse_1')" outlined dense
                hide-details single-line></v-autocomplete>
            </td> -->
            <td class="">
              <v-autocomplete
                v-model="item.id_e_marketplace"
                :disabled="!!item.id || !item.editing"
                :items="eMarketOptions"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.e_market')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="">
              <v-text-field
                v-model="item.display_name"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.shop_name')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td class="">
              <v-text-field
                v-model="item.e_marketplace_shop_id"
                :disabled="[5].includes(item.id_e_marketplace) || !item.editing"
                class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.shop_id')"
                outlined
                dense
                hide-details
                single-line
              ></v-text-field>
            </td>
            <td
              class="text-center"
              v-if="checkPermission(['administration_invoice'])"
            >
              <div class="d-flex align-center justify-center">
                <v-autocomplete
                  v-model="item.publish_invoice"
                  :items="publishInvoiceOptions"
                  :disabled="!item.editing"
                  class="c-input-small input-disabled-bold"
                  outlined
                  dense
                  hide-details
                  single-line
                ></v-autocomplete>
              </div>
            </td>
            <!-- <td v-if="checkPermission(['administration_invoice'])">
              <v-autocomplete
                v-model="item.id_customer_tax_code"
                :items="taxCodeOptions"
                :disabled="!item.editing"
                class="c-input-small input-disabled-bold"
                :label="$t('labels.tax_code')"
                outlined
                dense
                hide-details
                single-line
              ></v-autocomplete>
            </td>
            <td class="">
              <v-text-field v-model="item.e_marketplace_warehouse_code"
                :disabled="![2].includes(item.id_e_marketplace) || !item.editing"
                class="c-input-small input-disabled-bold" :placeholder="$t('labels.warehouse_code_e_market')" outlined
                dense hide-details single-line></v-text-field>
            </td>
            <td class="">
              <v-text-field v-model="item.address" :disabled="!item.editing" class="c-input-small input-disabled-bold"
                :placeholder="$t('labels.address')" outlined dense hide-details single-line></v-text-field>
            </td> -->
            <td class="text-center">
              <span :class="{ 'error--text': !item.token_status }">
                {{ $t(`labels.token_status_${item.token_status}`) }}
              </span>
              <br />
              <a
                v-if="item.id && authLink[item.id_e_marketplace]"
                :href="generateAuthLink(item)"
                class="v-btn v-btn--outlined theme--light v-size--x-small primary--text"
                target="_blank"
              >
                {{ $t("labels.connect") }}
              </a>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.active"
                  :disabled="!item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox
                  v-model="item.auto_gen_sku"
                  :disabled="!!item.id || !item.editing"
                  class="mt-0 ml-1"
                  :value="1"
                  hide-details
                ></v-checkbox>
              </div>
            </td>
            <!-- <td class="text-center">
              <div class="d-flex align-center justify-center">
                <v-checkbox v-model="item.auto_synchronize_stock" :disabled="!item.editing" class="mt-0 ml-1" :value="1"
                  hide-details></v-checkbox>
              </div>
            </td>
            <td class="text-center">
              <div class="d-flex align-center justify-center" v-if="[1, 2, 5].includes(item.id_e_marketplace)">
                <v-checkbox v-model="item.auto_synchronize_order" :disabled="!item.editing" class="mt-0 ml-1" :value="1"
                  hide-details></v-checkbox>
              </div>
            </td> -->
            <!--<td class="text-center">
            <div class="d-flex align-center justify-center">
              <v-checkbox v-if="item.auto_synchronize_order" v-model="item.is_dropoff" :disabled="!item.editing" class="mt-0 ml-1" :value="1" hide-details></v-checkbox>
            </div>
          </td>-->
            <!-- <td class="">
              <v-btn v-if="item.auto_synchronize_stock || item.auto_synchronize_order" :disabled="!item.editing" x-small
                color="info" @click="showDialogConfigSyncStock(item, index)">
                {{ $t('labels.config') }}
              </v-btn>
            </td> -->
            <td class="text-center">
              <template v-if="!item.editing">
                <v-btn
                  class="mb-1"
                  x-small
                  color="warning"
                  @click="toggleEditing(item, index, true)"
                >
                  {{ $t("labels.edit") }}
                </v-btn>
                <br />
                <v-btn
                  v-if="item.id"
                  x-small
                  color="success"
                  @click="showDialogConfigWarehouse(item)"
                >
                  {{ $t("labels.config") }}
                </v-btn>
              </template>
              <template v-else>
                <v-btn
                  class="mb-1"
                  x-small
                  color="success"
                  @click="updateShop(item)"
                >
                  {{ $t("labels.save") }}
                </v-btn>
                <br />
                <v-btn
                  x-small
                  color="error"
                  @click="toggleEditing(item, index, false)"
                >
                  {{ $t("labels.cancel") }}
                </v-btn>
              </template>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog v-model="dialogConfigSyncStock" persistent max-width="640px">
      <EMarketShopSyncConfig
        v-if="dialogConfigSyncStock && Object.keys(configItem).length > 0"
        :item="configItem"
        @close="hideDialogConfigSyncStock"
        @changeConfig="changeConfigSyncStock"
      />
    </v-dialog>

    <v-dialog v-model="dialogConfigWarehouse" persistent max-width="800px">
      <EMarketShopWarehouseConfig
        v-if="dialogConfigWarehouse && Object.keys(configItem).length > 0"
        :item="configItem"
        @close="hideDialogConfigWarehouse"
        @changeConfig="changeConfigSyncStock"
      />
    </v-dialog>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";
import moment from "moment";
import { E_MARKET_TIKTOK, PUBLISH_INVOICE_OPTIONS } from "@/libs/const";

export default {
  name: "EMarketShop",
  components: {
    SelectEMarket: () => import("@/components/common/SelectEMarket"),
    InputFilter: () => import("@/components/table/InputFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    // SelectWarehouse: () => import('@/components/table/SelectWarehouse'),
    EMarketShopSyncConfig: () =>
      import("@/components/account/EMarketShopSyncConfig"),
    EMarketShopWarehouseConfig: () =>
      import("@/components/account/EMarketShopWarehouseConfig"),
  },
  data: () => ({
    isLoading: false,
    filters: {},
    items: [],
    eMarketOptions: [],
    authLink: {},
    dialogConfigSyncStock: false,
    dialogConfigWarehouse: false,
    configItem: {},
    configIndex: -1,
    warehouseOptions: [],
    taxCodeOptions: [],
    publishInvoiceOptions: [...PUBLISH_INVOICE_OPTIONS],
  }),
  watch: {
    filters: {
      handler() {
        this.getShop();
      },
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.getEMarket();
    this.getEMarketAuthLink();
    this.getShop();
  },
  methods: {
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDialogConfigSyncStock(item, index) {
      this.configItem = { ...item };
      this.configIndex = index;
      this.dialogConfigSyncStock = true;
    },
    hideDialogConfigSyncStock() {
      this.configItem = {};
      this.configIndex = -1;
      this.dialogConfigSyncStock = false;
    },
    showDialogConfigWarehouse(item) {
      this.configItem = { ...item };
      this.dialogConfigWarehouse = true;
    },
    hideDialogConfigWarehouse() {
      this.configItem = {};
      this.dialogConfigWarehouse = false;
    },
    changeConfigSyncStock(item) {
      this.items[this.configIndex] = { ...item }; //this.configItem
      this.items = [...this.items];
      this.hideDialogConfigSyncStock();
    },
    configTimeChanged(time) {
      this.configItem = {
        ...this.configItem,
        synchronize_stock_start_time: time,
      };
    },
    addNewShop() {
      const item = {
        editing: true,
        id: null,
        id_warehouse: null,
        active: 1,
        publish_invoice: 0,
        auto_gen_sku: 0,
        auto_synchronize_stock: 0,
        display_name: null,
        e_marketplace_shop_id: null,
        e_marketplace_warehouse_code: null,
        id_e_marketplace: null,
        id_customer_tax_code: null,
        synchronize_stock_percent: 100,
        synchronize_stock_start_time: "00:00:00",
        synchronize_stock_type: 0,
        auto_synchronize_order: 0,
        synchronize_order_type: 0,
        is_dropoff: 0,
        token_status: "",
      };
      if (this.warehouseOptions.length === 1) {
        item.id_warehouse = this.warehouseOptions[0].value;
      }
      this.items.unshift(item);
    },
    toggleEditing(item, index, editing) {
      item.editing = editing;
      if (!item.id) {
        this.items = [...this.items].filter((i, idx) => idx !== index);
      } else {
        this.items[index] = { ...item };
      }
      this.items = [...this.items];
    },
    async updateShop(shop) {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/update-e-market-shop", shop);
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.update_success"));
        this.getShop();
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    async getEMarket() {
      const { data } = await httpClient.post("/list-e-market");
      this.eMarketOptions = [...data].map((e) => ({
        value: e.id,
        text: e.short_name || "",
      }));
      const { warehouses } = window.me;
      this.warehouseOptions = [...warehouses].map((w) => ({
        value: w.id,
        text: w.code_name || "",
      }));
    },
    async getShop() {
      try {
        const { data } = await httpClient.post(
          "/get-all-e-market-shop",
          this.filters
        );
        this.items = [...data].map((item) => {
          let token_status = "";
          if (item.expired_time) {
            const now = moment().unix();
            const accessExpired = moment(item.expired_time).unix();
            token_status = accessExpired > now ? 1 : 0;
          }
          return {
            ...item,
            token_status,
          };
        });
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
      }
    },
    async getEMarketAuthLink() {
      const { data } = await httpClient.post("/get-e-market-auth-link");
      this.authLink = { ...data };
    },
    generateAuthLink(shop) {
      if (shop.id_e_marketplace === E_MARKET_TIKTOK) {
        const ts = moment().unix();
        return `${this.authLink[shop.id_e_marketplace]}&state=s_${ts}_${
          shop.id
        }`;
      }
      return this.authLink[shop.id_e_marketplace];
    },
  },
};
</script>

<style scoped></style>
